<template>
  <div class="bg-white">
    <b-form
      ref="form"
      @submit.stop.prevent="onSubmit"
      novalidate
      :validated="formValid"
    >
      <p>การเปลี่ยนแปลงข้อมูลบัญชีธนาคารต้องการเวลา 1-2 วัน ในการตรวจสอบ</p>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="ธนาคาร:" class="mb-0">
            <b-form-select
              required
              v-model="model.selectedBank"
              :options="banks"
              value-field="code"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- เลือกธนาคาร --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group :label="`หมายเลขบัญชี:`" class="mb-0">
            <b-form-input
              v-model="model.accountNo"
              :required="!hasSetup"
              placeholder="Enter account number"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="ชื่อบัญชีธนาคาร:" class="mb-0">
            <b-form-input
              v-model="model.accountName"
              required
              placeholder="Enter bank account name"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="คำอธิบายอย่างย่อ :" class="mb-0">
            <b-form-input
              v-model="model.desc"
              placeholder="--- ไม่บังคับ ---"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <p style="color: red" class="mt-4">
        กรุณาตรวจสอบให้แน่ใจก่อนที่ท่านจะบันทึกข้อมูล
      </p>

      <div class="mt-4 d-flex">
        <div class="flex-grow-1"></div>
        <b-button class="mr-2" @click="cancel">ยกเลิก</b-button>
        <b-button variant="primary" type="submit">บันทึกข้อมูล</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  props: {
    banks: {
      type: Array,
      required: true,
    },
    initData: {
      type: Object,
      default: () => ({}),
    },
    hasSetup: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    formValid: false,
    model: {
      selectedBank: null,
      accountNo: "",
      accountName: "",
      desc: "",
      last_digits: "",
    },
  }),
  mounted() {
    if (this.initData != null) {
      this.model.selectedBank = this.initData.selectedBank || null;
      this.model.accountNo = this.initData.accountNo || null;
      this.model.accountName = this.initData.accountName || null;
      this.model.desc = this.initData.desc || null;
      this.model.last_digits = this.initData.last_digits || null;
    }
  },
  methods: {
    onSubmit() {
      this.formValid = true;
      let formPass = this.$refs.form.checkValidity();
      if (formPass) {
        this.$emit("onSubmit", this.model);
      } else {
        this.$emit("onFormFailed");
      }
    },
    clear() {
      this.formValid = false;
      this.model = {
        selectedBank: null,
        accountNo: "",
        accountName: "",
        desc: "",
        last_digits: "",
      };
    },
    cancel() {
      this.$emit("onCancel");
      this.$nextTick(() => {
        this.clear();
      });
    },
  },
};
</script>