<template>
  <div>
    <section class="section position-relative">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
            <accountMenu />
          </div>
          <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12">
            <div class="d-flex align-items-center justify-content-between">
              <h3 class="title">บัญชีธนาคาร</h3>
              <template v-if="enableOmiseFeature">
                <button @click="goToWithdraw" class="btn btn-outline-light">ถอนเงิน</button>
              </template>
            </div>

            <p class="border-bottom mt-2"></p>

            <p>ตั้งค่าเพื่อใช้สำหรับในการถอนเงินที่ได้จากการขายคอร์สเรียน</p>

            <template v-if="enableOmiseFeature">
              <template v-if="!hasSetup">
                <b-alert show variant="light">
                  <h4 class="alert-heading">ยังไม่ได้ตั้งค่า</h4>
                  <h5 class="alert-heading"></h5>
                  <h6>
                    กรุณาระบุข้อมูลบัญชีธนาคาร เพื่อใช้ในการถอนเงินออกจากระบบ
                    NERDOF
                  </h6>

                  <div class="mt-3 mb-2">
                    <b-button variant="outline-primary" @click="openForm">เพิ่มข้อมูลบัญชีธนาคาร</b-button>
                  </div>
                </b-alert>
              </template>
              <template v-if="model.last_digits != ''">
                <b-alert show variant="light">
                  <div class="d-flex align-items-top">
                    <div class="flex-grow-1">
                      <b-row>
                        <b-col cols="6">
                          <label class="mb-0">ธนาคาร:</label>
                          <h6>{{ currentAccount.bank_account.brand }}</h6>
                        </b-col>
                        <b-col cols="6">
                          <label class="mb-0">หมายเลขบัญชี:</label>
                          <h6>******{{ model.last_digits }}</h6>
                        </b-col>
                        <b-col cols="6">
                          <label class="mb-0">ชื่อบัญชี:</label>
                          <h6>{{ currentAccount.bank_account.name }}</h6>
                        </b-col>
                        <b-col cols="6">
                          <label class="mb-0">คำอธิบายอย่างย่อ:</label>
                          <h6>{{ currentAccount.description }}</h6>
                        </b-col>
                      </b-row>
                    </div>
                    <div>
                      <template v-if="accountVerified">
                        <h3>
                          <b-badge variant="success">
                            <i class="uil uil-check-circle"></i>
                            เปิดใช้งานแล้ว
                          </b-badge>
                        </h3>
                      </template>
                      <template v-else>
                        <h3>
                          <b-badge variant="warning">
                            <i class="uil uil-clock-three"></i>
                            รอดำเนินการ
                          </b-badge>
                        </h3>
                      </template>
                    </div>
                  </div>

                  <template v-if="accountVerified">
                    <div class="mt-3 mb-2">
                      <b-button variant="outline-primary" @click="openForm">เปลี่ยนแปลงข้อมูล</b-button>
                    </div>
                  </template>
                </b-alert>
              </template>
            </template>
            <template v-else>
              <b-alert show variant="light">
                <div class="text-center">
                  <div class="mb-4">
                    <b-img src="/images/monitoring.png" height="200"></b-img>
                  </div>
                  <h4 class="alert-heading">ยังไม่เปิดใช้งาน</h4>
                  <h6>เรากำลังพัฒนาปรับปรุง และจะเปิดใช้งานในเร็วๆนี้</h6>
                </div>
              </b-alert>
            </template>

            <b-modal
              title="แก้ไขข้อมูลธนาคาร"
              centered
              class="my-modal"
              v-model="modalBank"
              hide-footer
            >
              <bank-form
                ref="bankForm"
                :banks="banks"
                :init-data="model"
                :has-setup="hasSetup"
                @onSubmit="onSubmit"
                @onCancel="onCancelForm"
              ></bank-form>

              <b-overlay no-wrap :show="progressModal"></b-overlay>
            </b-modal>
          </div>
        </div>
      </div>

      <b-overlay :show="overlay" no-wrap></b-overlay>
    </section>
  </div>
</template>

<script>
import accountMenu from "@/views/account/menu.vue";
import bankService from "../../../services/bankAccount";
import BankForm from "../../../components/bank-form";
export default {
  components: {
    accountMenu,
    BankForm
  },
  data: () => ({
    hasSetup: false,
    accountVerified: false,
    formValid: false,
    banks: [],
    currentAccount: null,
    model: {
      selectedBank: null,
      accountNo: "",
      accountName: "",
      desc: "",
      last_digits: ""
    },
    showForm: false,
    overlay: false,
    modalBank: false,
    progressModal: false
  }),
  mounted() {
    if (this.enableOmiseFeature) {
      this.init();
    }
  },
  computed: {
    enableOmiseFeature() {
      return this.$store.state.enableOmiseFeature || false;
    }
  },
  watch: {
    modalBank(val) {
      if (val == false) {
        this.$nextTick(() => {
          setTimeout(() => {
            try {
              this.$refs.bankForm.clear();
            } catch (error) {
              // do nothing
            }
          }, 100);
        });
      }
    }
  },
  methods: {
    init() {
      this.overlay = true;
      bankService.getBankList().then(resp => {
        this.banks = resp.data;
        this.getCurrentAccount();
      });
    },
    getCurrentAccount() {
      bankService
        .getBankAccount()
        .then(resp => {
          if (resp.data.length > 0) {
            this.hasSetup = true;
            const currentBank = resp.data[0];
            if (currentBank.verified === true && currentBank.active === true) {
              this.accountVerified = true;
            } else {
              this.accountVerified = false;
            }

            this.model.selectedBank = currentBank.bank_account.bank_code;
            this.model.accountNo = "";
            this.model.accountName = currentBank.bank_account.name;
            this.model.desc = currentBank.description;
            this.model.last_digits = currentBank.bank_account.last_digits;

            this.currentAccount = currentBank;
          } else {
            // not setup yet
            this.currentAccount = null;
            this.hasSetup = false;
            this.accountVerified = false;
          }
        })
        .catch(err => {
          // do nothing
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    addOrUpdateBankAccount(newData) {
      // generate data
      let data = {
        name: newData.accountName,
        description: newData.desc,
        bank_account: {
          name: newData.accountName,
          number: newData.accountNo,
          bank_code: newData.selectedBank
        }
      };

      if (this.hasSetup == true) {
        if (this.model.accountNo == null || this.model.accountNo == "") {
          delete data.bank_account.number;
        }
        return bankService.updateBankAccount(this.currentAccount.id, data);
      } else {
        return bankService.addBankAccount(data);
      }
    },
    openForm() {
      this.modalBank = true;
    },
    onCancelForm() {
      this.modalBank = false;
    },
    onSubmit(data) {
      this.modalBank = false;
      this.overlay = true;
      this.addOrUpdateBankAccount(data)
        .then(resp => {
          if (resp.data == true) {
            this.getCurrentAccount();
            this.formValid = false;
          }
        })
        .catch(err => {})
        .finally(() => {
          // do something
          this.overlay = false;
        });
    },
    goToWithdraw() {
      this.$router.push({
        name: "Balance",
        query: {
          form: true
        }
      });
    }
  }
};
</script>

<style scoped>
.border-bottom-cus {
  border-bottom: 5px solid !important;
  color: rgba(0, 201, 167, 0.1);
}
</style>